<template>
  <div>
    <v-container fluid>
      <h1 class="text-center" style="margin-top:30vh;color:red;">
        {{ $t('NoPermission') }}
      </h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "noPermission",
  data() {
    return {
      system_data: {}
    };
  },
  methods: {
    initData() {
      this.$store.dispatch(`home/getData`).then((res) => {
        this.system_data = res.data;
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>
